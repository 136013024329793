<!-- 登录页面 -->
<template>
  <div class="LOGIN_BG">
    <div class="wrap">
      <div class="left_company">
        <img src="@/assets/images/images/bg_ligon.png" alt="" />
      </div>
      <div class="login_form">
        <div>精 英 登 录</div>
        <div v-if="type == 'xiaochengxu'">
          <div id="code">
            <div v-if="guoqi" @click="get_pc_creatqrcode">
              二维码失效，点击刷新
            </div>
            <img :src="value_set_img" alt="" />
          </div>
        </div>
        <div class="shuru" v-else>
          <div class="ipt_wrap">
            <input
              type="text"
              placeholder="请输入手机号"
              maxlength="11"
              class="ipt"
              v-model="phone"
            />
          </div>
          <div class="ipt_wrap">
            <div>
              <input
                type="text"
                placeholder="请输入验证码"
                class="yanzhengma"
                v-model="code"
              />
              <div type="text" class="btnY">
                <span v-if="sendMsgDisabled">{{
                  jishi_time + "秒后获取"
                }}</span>
                <span v-if="!sendMsgDisabled" @click="mysend_code"
                  >发送验证码</span
                >
              </div>
            </div>
          </div>
          <button
            type="button"
            class="el-button enter el-button--default"
            @click="enterIN"
          >
            <!----><!----><span>登&emsp;录</span>
          </button>

          <div class="agreement">
            <el-checkbox v-model="checkbox">我同意</el-checkbox>
            <span @click="$router.push('/agree')">《咖聘隐私政策》</span>
            和
            <span @click="$router.push('/conceal')">《咖聘使用协议》</span>
          </div>
        </div>

        <div class="jiao">
          <p class="title_top" @click="qiehuan">{{ btn_title }}</p>
          <div><!----></div>
          <div>
            <img
              v-if="type == 'xiaochengxu'"
              src="@/assets/images/images/sjh.png"
              alt=""
            />
            <img v-else src="@/assets/images/images/wx.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  pc_creatqrcode,
  pc_get_rangecode,
  send_code,
  elite_login_pc,
} from "@/api/login.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      value_set: "",
      value_set_img: "",
      //登录按钮状态
      loading: false,
      //是否同意协议
      checkbox: false,

      phone: "", //手机号
      code: "", //验证码

      type: "xiaochengxu",
      btn_title: "手机号登录",
      guoqi: false,
      timer: null, //二维码定时器

      sendMsgDisabled: false,
      jishi_time: 60, // 发送验证码倒计时
      send_timer: null, //短信定时器
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    qiehuan() {
      if (this.type == "xiaochengxu") {
        (this.type = "shoujihao"), (this.btn_title = "微信扫码登录");
        //切换到短信登录，取消监听轮询
        clearInterval(this.timer);
      } else {
        (this.type = "xiaochengxu"), (this.btn_title = "手机号登录");
        //重新监听轮询
        this.timer = setInterval(this.get_rangecode.bind(this), 3000);
      }
    },

    enterIN() {
      if (this.loading === false) {
        this.enter();
      } else {
        return;
      }
    },
    enter() {
      console.log(this.checkbox);
      if (!this.checkbox) {
        this.$message({
          message: "请同意下方协议",
          type: "warning",
        });
      } else {
        if (this.phone === "") {
          this.$message({
            message: "请填写手机号",
            type: "warning",
          });
          return;
        }
        if (this.code === "") {
          this.$message({
            message: "请填写短信验证码",
            type: "warning",
          });
          return;
        }
        this.loading = true;
        elite_login_pc({ phone: this.phone, code: this.code }).then((res) => {
          console.log("短信登录成功", res);

          if (res.code === 0) {
            this.loading = false;
            console.log("短信登录成功", res);
            //保存登录状态
            sessionStorage.setItem("token", res.data.token);

            sessionStorage.setItem("weixin_nickname", res.data.weixin_nickname);

            sessionStorage.setItem("weixin_pic", res.data.weixin_pic);

            this.$router.replace("/elite_resume");

            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.loading = false;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      }
    },

    //发送验证码
    mysend_code: function () {
      let that = this;
      console.log("发送验证码");

      if (this.phone == "") {
        this.$message({
          message: "请填写手机号",
          type: "warning",
        });
      } else {
        send_code({ phone: this.phone }).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: "发送成功！",
              type: "success",
            });

            this.sendMsgDisabled = true;
            that.send_timer = window.setInterval(function () {
              if (that.jishi_time-- <= 0) {
                that.jishi_time = 60;
                that.sendMsgDisabled = false;
                window.clearInterval(that.send_timer);
              }
            }, 1000);
          }
        });
      }
    },
    //  获取登录二维码
    get_pc_creatqrcode: function () {
      pc_creatqrcode().then((res) => {
        if (res.code === 0) {
          sessionStorage.setItem("value_set", res.data.value_set);

          this.value_set = res.data.value_set;

          this.value_set_img = res.data.pc_login_file.file_url;

          this.timer = setInterval(this.get_rangecode.bind(this), 3000);

          this.guoqi = false;
        }

        this.loading = false;
      });
    },
    //轮询获取值
    get_rangecode: function () {
      pc_get_rangecode({ search_data: { value_set: this.value_set } }).then(
        (res) => {
          if (res.code === 0) {
            console.log(res);

            if (res.data.code == 1) {
              //保存登录状态
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem(
                "weixin_nickname",
                res.data.weixin_nickname
              );

              sessionStorage.setItem("weixin_pic", res.data.weixin_pic);

              console.log(res.data.token);
              //登录成功 如果没有简历则让他跳转到填写简历页面，如果有简历就跳转到上一页
              this.$router.replace("/elite_resume");
              clearInterval(this.timer);
            } else if (res.data.code == 2) {
              //随机数已经过期，停止轮询
              clearInterval(this.timer);

              this.guoqi = true;
            }
            console.log(res.data);
          }

          this.loading = false;
        }
      );
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.get_pc_creatqrcode();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    clearInterval(this.timer);
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding-top: 100px;
  box-sizing: border-box;
  /* background-color: red; */
}
/* 解决高度塌陷 */
.wrap::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

.LOGIN_BG {
  width: 100%;
  min-height: 100%;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding-top: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.wrap:after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
.company {
  float: left;
  line-height: 500px;
  position: relative;
  left: 20px;
}
.logo {
  width: 100px;
}
.logo img {
  width: 100%;
  height: 100%;
}
.company div {
  width: 700px;
  height: 560px;
  margin: 0;
  font-size: 41px;
  color: #4d4d4d;
  cursor: pointer;
}
.company div span:nth-child(2) {
  margin-right: 16px;
}
.left_company {
  width: 600px;
  float: left;
}
.login_form {
  float: right;
  width: 600px;
  height: 700px;
  -webkit-box-sizing: border-box;
  padding: 37px 40px 30px;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
}
.login_form > div:first-child {
  font-size: 23px;
  color: #666;
  text-align: center;
  margin-bottom: 12px;
}
.shuru {
  width: 400px;
  margin: auto;
  margin-top: 124px;
}
.ipt {
  border-radius: 16px;
  text-indent: 20px;
  font-size: 16px;
}
.ipt,
.ipt_wrap > div {
  height: 52px;
  width: 100%;
  -webkit-box-shadow: inset 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  box-shadow: inset 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  color: #666;
}
.ipt_wrap > div {
  margin-top: 60px;
  margin-right: 12px;
  border-radius: 10px;
  overflow: hidden;
}
.yanzhengma {
  height: 52px;
  background: none;
  text-indent: 20px;
  font-size: 16px;
}
.btnY,
.false {
  line-height: 52px;
  float: right;
  padding-right: 20px;
  cursor: pointer;
  font-size: 14px;
}
.false {
  color: #f83434 !important;
}
.agreement {
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #e02e0e;
}
.agreement span {
  cursor: pointer;
  font-size: 14px;
}
.enter {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #ff8f00;
  color: #fff;
  border: none;
  font-size: 18px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.reg {
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  color: #666;
}
.reg:hover {
  color: #ff8f00;
}
div .el-checkbox__inner:hover {
  border-color: #ff8f00;
}
div .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff8f00;
}
div .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff8f00;
  border-color: #ff8f00;
}
div .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #ff8f00;
}
.jiao {
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  height: 60px;
  cursor: pointer;
  padding: 15px;
}
.jiao img {
  width: 20px;
}
#code {
  text-align: center;
  width: 364px;
  height: 364px;
  padding-top: 22px;
  margin: auto;
  margin-bottom: 29px;
  position: relative;
}
#code img {
  height: 320px;
}
#code div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 364px;
  background: rgba(39, 39, 39, 0.8);
  color: #f0f8ff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.title_top {
  position: absolute;
  width: 109px;
  right: 50px;
  color: #fff;
  top: 1px;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  background: #080808;
  border-radius: 4px;
}
</style>
